import React from "react";
import "./Solutions.scss";
import Configure from "../assets/configure.svg";
import Manage from "../assets/manage.svg";
import Processing from "../assets/processing.svg";
import Filter from "../assets/filter.svg";
import Investigate from "../assets/investigation.svg";
import { useNavigate } from "react-router-dom";

function Process() {
  const navigate = useNavigate();

  const handleBookDemo = () => {
    navigate("/meeting");
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <section id="process-section">
      <section className="process">
        <h2>Making Fraud Risk Prevention</h2>
        <h2>A Simple 5 Steps Process</h2>

        <div className="process-content">
          <div className="step">
            <div className="header-number">
              <p className="step-number">Step 1:</p>
              <p>Configure</p>
            </div>
            <div className="img-and-text">
              <ul>
                <li>
                  <p>
                    Pick from the standard fraud risk library or customize fraud
                    risk relevant to your business
                  </p>
                </li>
                <li>
                  <p>
                    Hundreds of fraud risks covering P2P, O2C, payroll, bribery
                    & corruption, regulatory risks and other modules
                  </p>
                </li>
                <li>
                  <p>Configure quantitative parameters and bench marks</p>
                </li>
              </ul>

              <img src={Configure} alt="" />
            </div>
          </div>
          <div className="step" id="manage-left">
            <div className="header-number">
              <p className="step-number">Step 2:</p>
              <p>Manage Data</p>
            </div>
            <div className="img-and-text-left">
              <ul>
                <li>
                  <p>Integrate any data</p>
                </li>
                <li>
                  <p>Prepopulated data templates</p>
                </li>
                <li>
                  <p>
                    Integrate in real-time or define your own period for
                    review
                  </p>
                </li>
              </ul>
              <img src={Manage} alt="" />
            </div>
          </div>
          <div className="step">
            <div className="header-number">
              <p className="step-number">Step 3:</p>
              <p>Processing</p>
            </div>
            <div className="img-and-text">
              <ul>
                <li>
                  <p>Powerful visualization of suspicious transactions</p>
                </li>
                <li>
                  <p>
                    Get real-time insights into your fraud risk management
                    process
                  </p>
                </li>
                <li>
                  <p>Use Gen AI for query</p>
                </li>
                <li>
                  <p>Single Source for analysis</p>
                </li>
              </ul>
              <img src={Processing} alt="" />
            </div>
          </div>
          <div className="step" id="filter-left">
            <div className="header-number">
              <p className="step-number">Step 4:</p>
              <p>Filtering</p>
            </div>
            <div className="img-and-text-left">
              <ul>
                <li>
                  <p>Maximum detection accuracy through intelligent QC</p>
                </li>
                <li>
                  <p>
                    Powerful in-app QC features to minimize false positives
                  </p>
                </li>
                <li>
                  <p>Use Gen AI to search risks</p>
                </li>
                <li>
                  <p>Powerful AI</p>
                </li>
              </ul>
              <img src={Filter} alt="" />
            </div>
          </div>
          <div className="step">
            <div className="header-number">
              <p className="step-number">Step 5:</p>
              <p>Investigation</p>
            </div>
            <div className="img-and-text">
              <ul>
                <li>
                  <p>Powerful investigation module</p>
                </li>
                <li>
                  <p>
                    Investigate the identified fraud risk- internally or
                    externally
                  </p>
                </li>
                <li>
                  <p>
                    Insightful investigation KPIs to track the efficiency of
                    your FRM process
                  </p>
                </li>
                <li>
                  <p>Powerful AI</p>
                </li>
              </ul>
              <img src={Investigate} alt="" />
            </div>
          </div>
        </div>

        <button onClick={() => handleBookDemo()}>
          See How It Works: Schedule a Demo
        </button>
      </section>
    </section>
  );
}

export default Process;
