import React from "react";
import "./foundation.scss";
import Connect from "./connect.svg";
import Library from "./library.svg";
import Orchestrate from "./orchestrate.svg";
import Monitor from "./monitor.svg";
function Focus() {
  return (
    <section className="foundation-features">
      <h1>Key Features</h1>
      <div className="sub-heading">
        <span>
          The Data Foundation™ engine works as the data translator between your
          transaction systems and the Fortifai engine.  It has 4 key modules to
          address different aspects of the integration process:
        </span>
      </div>

      <div className="focus-content">
        <div className="focus-parameter">
          <img src={Connect} alt="" />
          <div className="child-parameter">
            <h4>Data Connectors</h4>
            <span>
              Connects to your data via SFTP, DB access or API access, depending
              on your system’s configuration
            </span>
          </div>
        </div>
        <div className="focus-parameter">
          <img src={Library} alt="" />
          <div className="child-parameter">
            <h4>ETL Library</h4>
            <span>
              Scalable for big data transformation functions in Spark for
              Joins, merges, filtering, transformation and aggregation
            </span>
          </div>
        </div>
        <div className="focus-parameter">
          <img src={Orchestrate} alt="" />
          <div className="child-parameter">
            <h4>Orchestration</h4>
            <span>
              Schedule and trigger-based data orchestration capability to
              orchestrate data integration with multiple transaction systems and
              consume in the FortifAI engine
            </span>
          </div>
        </div>
        <div className="focus-parameter">
          <img src={Monitor} alt="" />
          <div className="child-parameter">
            <h4>Quality Monitor</h4>
            <span>
              Data quality rules embedded in the Fortifai engine related to
              expected values, missing values, incorrect formats and master data
              mismatch.
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Focus;
