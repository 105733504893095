import "./ProblemSolving.scss";
const ProblemSolving = () => {
  return (
    <>
      <section className="problem-solving-heading-container">
        <div className="center-div">
          <h2>Problems we are solving</h2>
          <p>
            FortifAI has been designed to combat fraud at all levels - be it
            internal fraud, regulatory risk failures or avoiding global
            compliance and reputational risks
          </p>
          <button>
            <a className="a-tag" href="/meeting">
              Schedule a Demo
            </a>
          </button>
        </div>
      </section>
      <section className="risk-scenarios">
        <h2>Risk scenario covered by FortifAI</h2>
        <p>
          FortifAi’s fraud risk algorithm will enable you to combat internal
          fraud while the 3rd party background search features fully integrated
          into the work-flow will enable you to monitor external risks
        </p>

        <div className="risk-container">
          <div className="internal-fraud" id="combat-fraud">
            <span>Combat Internal Fraud</span>
            <ul>
              <li>Procure to Pay</li>
              <li>Order to Cash</li>
              <li>Payroll</li>
              <li>Kickbacks</li>
              <li>Data Governance</li>
              <li>Process/Control violations</li>
              <li>Conflict of Interest</li>
              <li>Bribery & Corruption</li>
            </ul>
          </div>
          <div className="internal-fraud" id="monitor-risk">
            <span>Monitor Regulatory Risk*</span>
            <ul>
              <li>Global Sanctions</li>
              <li>Financial Crime Compiilances</li>
              <li>AML Penalties</li>
              <li>Internal Watchlist</li>
              <li>Country Watchlists</li>
              <li>Enforcement Actions</li>
              <li>Related Parties</li>
            </ul>
          </div>
          <div className="internal-fraud">
            <span>Monitor Global Compliance and Reputational Risk*</span>
            <ul>
              <li>PEP (Politically Exposed Person)</li>
              <li>Adverse Press</li>
              <li>Blacklisting</li>
              <li>Litigation Coverage</li>
              <li>Defaulters</li>
            </ul>
          </div>
        </div>
        <div className="special-mention">
          *Based on 3rd party service providers
        </div>
      </section>
    </>
  );
};

export default ProblemSolving;
