import React from "react";
import "./KeyFeatures.scss";
import HomeImg from "../assets/feature-home.png";
import Img2 from "../assets/image (4).png";
import Img3 from "../assets/image (5).png";
import Img4 from "../assets/image (6).png";
import Img5 from "../assets/image (7).png";
import Img6 from "../assets/image (8).png";
import Img7 from "../assets/image (9).png";
import DemoScreen from "../assets/powerful.png";
import Report_point from "../assets/Report_point.png";
import thirdparty from "../assets/3rd_party.png";
import { useNavigate } from "react-router-dom";
function Resource() {
  const navigate = useNavigate();

  const handleBookDemo = () => {
    navigate("/meeting");
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <section>
      <section className="feature-home">
        <div className="center-div">
          <div className="left-container">
            <h2>Key Features</h2>
            <p>
              Your Advantage Starts Here: Explore Features for a Smarter Fraud
              Defense
            </p>
            <button onClick={() => handleBookDemo()}>Schedule a Demo</button>
          </div>
          <div className="right-container">
            <img src={HomeImg} alt="" />
          </div>
        </div>
      </section>
      <section className="features-frame">
        <h2>Key Features</h2>
        <div className="frame-unit">
          <div className="image-div-mobile">
            <img src={Img2} alt="" id="img2" />
          </div>
          <div className="text-div">
            <h3>
              Hundreds of pre-configured fraud risk scenarios to combat fraud.
              Quick and easy customization to cover your business risk.
            </h3>
            <ul>
              <li>
                A large number of pre-configured fraud risk scenarios that
                combat fraud throughout your business value chain
              </li>
              <li>
                Configurable quantitative parameters for individual fraud risk
                situation
              </li>
            </ul>
          </div>
          <div className="image-div">
            {/* <img src={Img2} alt="" id="img2" /> */}
            <img src={Img4} alt="" id="img4" />
          </div>
        </div>
      </section>
      <section className="features-frame">
        <div className="frame-unit">
          <div className="image-div">
            <img src={Img3} alt="" id="img3" />
          </div>
          <div className="image-div-mobile">
            <img src={Img3} alt="" id="img3" />
          </div>
          <div className="text-div">
            <h3>
              Formidable false positive management engine, powered by AI, will
              gradually lower false positive rate.
            </h3>
            <p>
              Allows for red/green flagging transactions and investigation
              assignment, submission and approvals while ensuring a fully
              digital trail of the process
            </p>
          </div>
        </div>
      </section>
      <section className="features-frame">
        <div className="frame-unit">
          <div className="image-div-mobile">
            <img src={thirdparty} alt="" id="thirdparty" />
          </div>
          <div className="text-div">
            <h3>
              Powerful and independent 3rd party background search feature to
              conduct due diligence on any subject, saving time and money
            </h3>
            <p>
              FortifAi comes embedded with an advanced background search feature
              that enables organizations to conduct on-the-spot due diligence.
              This saves time and cost for the business, enables rapid
              intervention and prevents dealings with dubious parties
            </p>
          </div>
          <div className="image-div">
            <img src={thirdparty} alt="" id="thirdparty" />
          </div>
        </div>
      </section>
      <section className="features-frame">
        <div className="frame-unit">
          <div className="image-div">
            <img src={Img5} alt="" id="img5" />
          </div>
          <div className="image-div-mobile">
            <img src={Img5} alt="" id="img5" />
          </div>
          <div className="text-div">
            <h3>
              Easily integrates with any data systems as per your convenience
            </h3>
            <p>
              Data as a product philosophy for backend integrations. 
              <p>Connect
              through multiple options :</p>
              <ul>
                <li>Connector options with SSL</li>
                <li>Database access</li>
                <li> API</li>
                <li> SFTP</li>
              </ul>
            </p>
          </div>
        </div>
      </section>
      {/* <section className="features-frame">
        <div className="frame-unit">
        <div className="image-div-mobile">
            <img src={Img6} alt="" id="img6" />
          </div>
          <div className="text-div">
            <h3>Intelligent to deliver insights to you automatically</h3>
            <p>
              The engine automatically identifies key anomalies and patterns in
              the transactions to give a quick snapshot of the business
            </p>
          </div>
          <div className="image-div">
            <img src={Img6} alt="" id="img6" />
          </div>
        </div>
      </section> */}
      <section className="features-frame">
        <div className="frame-unit">
          <div className="image-div-mobile">
            <img src={DemoScreen} alt="" id="img7" />
          </div>
          <div className="text-div">
            <h3>
              Powerful, insightful visualisation and automated insights that
              facilitate prioritisation and rapid intervention
            </h3>
            <p>
              FortifAI automatically identifies key anomalies and patterns in
              the transactions to give a quick snapshot of the business risk for
              you to take actions
              {/* <ul>
                <li>
                  Delegate investigations to internal or external investigators
                </li>
                <li>Upload data and evidence</li>
                <li>
                  Maintain the evidentiary value of the data used in the
                  investigation
                </li>
                <li>
                  Powerful Investigation Dashboard that tracks critical KPI
                  related to the investigation management process
                </li>
              </ul> */}
            </p>
          </div>
          <div className="image-div">
            <img src={DemoScreen} alt="" id="img7" />
          </div>
        </div>
      </section>
      <section className="features-frame">
        <div className="frame-unit">
          <div className="image-div">
            <img src={Img7} alt="" id="img8" />
          </div>
          <div className="image-div-mobile">
            <img src={Img7} alt="" id="img8" />
          </div>
          <div className="text-div">
            <h3>
              A Robust incident response module that facilitates in-app
              investigation
            </h3>
            <p>
              <ul>
                <li>
                  Delegate investigations to internal or external investigators
                </li>
                <li>Upload data and evidence</li>
                <li>
                  Maintain the evidentiary value of the data used in the
                  investigation
                </li>
                <li>
                  Investigation Dashboard that tracks critical investigation
                  related KPIs
                </li>
              </ul>
            </p>
          </div>
        </div>
      </section>
      <section className="features-frame">
        <div className="frame-unit">
          <div className="image-div-mobile">
            <img src={Report_point} alt="" id="img7" />
          </div>
          <div className="text-div">
            <h3>
              Fully automated one-click “Download Report” with full evidentiary
              value
            </h3>
            <p>
              Get all critical information related to the investigation case in
              one click. The report is legally defensible as it is fully
              automated and is generated by the system without any human
              intervention
              {/* <ul>
                <li>
                  Delegate investigations to internal or external investigators
                </li>
                <li>Upload data and evidence</li>
                <li>
                  Maintain the evidentiary value of the data used in the
                  investigation
                </li>
                <li>
                  Powerful Investigation Dashboard that tracks critical KPI
                  related to the investigation management process
                </li>
              </ul> */}
            </p>
          </div>
          <div className="image-div">
            <img src={Report_point} alt="" id="img7" />
          </div>
        </div>
      </section>

      <button
        className="stop-fraud-demo-button"
        onClick={() => handleBookDemo()}
      >
        Stop Fraud Now: Book a Demo
      </button>
    </section>
  );
}

export default Resource;
