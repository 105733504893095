import React from "react";
import "./About.scss";
import { useNavigate } from "react-router-dom";

function Marketecture() {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function handleKnowMoreClick() {
    scrollToTop();
    navigate("/about");
  }
  return (
    <section className="about-container">
      <h3>ABOUT US</h3>
      <div className="about-us-heading-container">
        <div className="about-us-heading-title">
          We are on a mission to digitize and automate
        </div>
        <div className="about-us-heading-title">
          the fraud risk management process
        </div>
      </div>
      <div className="about-the-company">
        <p>
          The Fraud Risk Management value chain is broken. It is structured around
          investigating fraud after the fact, rather than preventing it. 
          The entire
          fraud risk management process is post-mortem. 
          Frauds are detected and
          investigated after the fact.
        </p>

        <p>
          FortifAI.io is on a mission to change this. Our proprietary SaaS
          platform automates suspicious transaction detection at an organisation
          level. Automated insights generated by FortifAI also help them focus
          on systems and control improvements. 
        </p>

        <button onClick={handleKnowMoreClick}>Know More</button>
      </div>
    </section>
  );
}

export default Marketecture;
