import React from "react";
import "./Focus.scss";
import Loop from "../assets/loop.svg";
import Trust from "../assets/trust.svg";
import Risk from "../assets/Risk.svg";
import Value from "../assets/value.svg";
import Report from "../assets/report.svg";
import { useNavigate } from "react-router-dom";
function Focus() {
  const navigate = useNavigate();

  const handleBookDemo = () => {
    navigate("/meeting");
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <section className="focus">
      <h1>Why Fortifai?</h1>
      <div className="sub-heading">
        <span>Save Cost, Prevent Reputational Loss and</span>
        <br />
        <span className="bottom-span">Improve Control Environment</span>
      </div>

      <div className="focus-content">
        <div className="focus-parameter">
          <img src={Report} alt="" />
          <div className="child-parameter">
            <h4>Improve the Efficiency of the Audit Department</h4>
            <span>
              FortifAI automates and digitizes the entire fraud risk management
              process including investigation, reporting and performance
              management.
            </span>
          </div>
        </div>
        <div className="focus-parameter">
          <img src={Value} alt="" />
          <div className="child-parameter">
            <h4>Manage Risk at an Organization Level</h4>
            <span>
              FortifAI can integrate and process data at an organization level
              to identify all the fraud risks.
            </span>
          </div>
        </div>
        <div className="focus-parameter">
          <img src={Risk} alt="" />
          <div className="child-parameter">
            <h4>Save Cost</h4>
            <span>Prevent cost leakages and reduce consulting cost.</span>
          </div>
        </div>
        <div className="focus-parameter">
          <img src={Loop} alt="" />
          <div className="child-parameter">
            <h4>Focus on Internal Control Improvement</h4>
            <span>
              Focus on improving internal controls rather than data analytics.
            </span>
          </div>
        </div>
        <div className="focus-parameter">
          <img src={Trust} alt="" />
          <div className="child-parameter">
            <h4>Increase Shareholder Value</h4>
            <span>
              Improved governance thorugh timely detection or prevention of
              fraud significantly adds to the enterprise value of the
              organisation increasing shareholder value.
            </span>
          </div>
        </div>
      </div>

      <button onClick={() => handleBookDemo()}>Schedule a Demo</button>
    </section>
  );
}

export default Focus;
