import React from "react";
import "./Solutions.scss";
import Digital from "../assets/digital-slide.png";
import Holistic from "../assets/holistic-slide.png";
import Intelligent from "../assets/intelligent-slide.png";
function SolutionFeatures() {
  return (
    <section>
      <section className="solution-features">
        <h2>Digital. Holistic. Intelligent</h2>

        <div className="digital">
          <h3>Digital</h3>
          <div className="feature-unit">
            <img src={Digital} alt="" />
            <div>
              <ul>
                <li>
                  Digitalizes the full process of audit and risk management in
                  an organization
                </li>
                <li>
                  Full audit trail of data along with ownership of
                  responsibility across the process
                </li>
                <li>
                  Enterprise-ready architecture with seamless integration with
                  transaction systems
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="holistic">
          <h3>Holistic</h3>
          <div className="feature-unit">
            <div>
              <ul>
                <li>
                  Configure fraud risk relevant to your business – pick from the
                  standard fraud risk library or custom fraud risk to cover
                  areas relevant to your business
                </li>
                <li>
                  Fraud risk coverage for P2P, O2C, Payroll, Company Asset,
                  Payments or Regulatory Risks
                </li>
                <li>
                  Eliminate false positives, conduct in-app background checks on
                  vendors, customers and individuals
                </li>
                <li>
                  Investigate red flagged transactions & generate investigation
                  reports, manage the efficiency of your fraud risk management
                  function through a powerful investigation performance
                  dashboard
                </li>
                {/* <li>
                  Enables the ability to quickly incorporate bespoke risk
                  scenarios in the engine
                </li>
                <li>
                  Employs learning models for severity and treatment of risky
                  financial transactions (RFTs)
                </li>
                <li>
                  Offers conversational ability to monitor and identify risky
                  transactions
                </li> */}
              </ul>
            </div>
            <img src={Holistic} alt="" />
          </div>
        </div>
        <div className="digital">
          <h3>Intelligent</h3>
          <div className="feature-unit">
            <img src={Intelligent} alt="" />
            <div>
              <ul>
                <li>
                  A comprehensive suite of risk scenarios available out of the
                  box based on in-house SMEs
                </li>
                <li>
                  Ability to quickly incorporate bespoke risk scenarios in the
                  engine
                </li>
                <li>Learning model for severity and treatment of RFTs</li>
                <li>
                  Conversational ability to monitor and identify risky
                  transactions
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default SolutionFeatures;
