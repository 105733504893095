import React from "react";
import "./Home.scss";
import About from "../About/About";
import Features from "../Features/Features";
import Focus from "../Focus/Focus";
import DemoScreen from "../assets/demo screen.svg";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

function Home() {
  const navigate = useNavigate();

  const handleBookDemo = () => {
    navigate("/meeting");
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>FortifAI</title>
        <meta
          name="description"
          content="End to end software platform for fraud prevention"
        />
        <meta property="og:title" content="FortifAI" />
        <meta
          property="og:description"
          content="End to end software platform for fraud prevention"
        />
      </Helmet>
      <section>
        <section className="home-section">
          <div className="center">
            <div className="left-container">
              <div className="heading">
                {/* <span>A Game Changer in</span>
                <span>Fraud risk management</span> */}
                <span>Enhance Fraud Risk Management Efficiency and Accelerate Time to Resolution</span>
              </div>

              <div className="sub-text">
                <p>
                  Monitor organization-wide transactions in real-time to detect
                  and investigate suspicious transactions. Prevent fraud before
                  it happens.
                </p>
              </div>
              <div className="demo-button">
                <button onClick={() => handleBookDemo()}>
                  SCHEDULE A DEMO NOW
                </button>
              </div>
            </div>
            <div className="right-container">
              <img src={DemoScreen} alt="" />
            </div>
          </div>
        </section>

        <Features />
        <Focus />

        <About />
      </section>
    </>
  );
}

export default Home;
