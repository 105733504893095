import "./Pricing.scss";
import Img from "../assets/pricing_image.png";
import pricing_tick from "../assets/pricing_tick.png";
import model2 from "../assets/model2.png";
import model1 from "../assets/model1.png";
import blueTick from "../assets/blue_tick.svg";
import { useNavigate } from "react-router-dom";
const Pricing = () => {
  const cards = [
    "Low implementation fee",
    " Low customization fee",
    "Free 24X7 technical support",
    "Dedicated expert forensic investigators to ensure success",
    "Competitive and affordable pay-as-you-go pricing",
    "Quarterly or annual license fee based on the number of IT systems to be connected",
  ];
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleBookDemo = () => {
    navigate("/meeting");
    scrollToTop();
  };
  return (
    <div className="pricing-container">
      <section className="pricing-home">
        <div className="center-div">
          <div className="left-container">
            <h2>Pricing</h2>
            <p>
              The most powerful fraud risk prevention software ever yet the most
              price competitive.
            </p>
            <div className="pricing-button" onClick={handleBookDemo}>
                Schedule a Free Trial
            </div>
          </div>
          <div className="right-container">
            {/* <img src={Img} alt="" /> */}
          </div>
        </div>
      </section>

      <div className="pricing-content">
        <div className="pricing-content-title-container">
          <div className="pricing-content-title">
            FortifAI has been built from the ground up using the first
            principles
          </div>
          <div className="pricing-content-title">
            technique of innovation. We have taken all care to ensure that we
            provide
          </div>
          <div className="pricing-content-title">
            you with the features that you need to effectively manage your
            fraud
          </div>
          <div className="pricing-content-title">
            risk in a very cost-effective manner.
          </div>
        </div>

        <div className="pricing-content-cards-container">
          {cards.map((card, index) => (
            <div className="pricing-content-card" key={index}>
              <div className="pricing-content-card-icon">
                <img
                  src={blueTick}
                  alt="blue tick"
                  className="blue-tick-icon"
                />
              </div>
              <div className="pricing-content-card-text">{card}</div>
            </div>
          ))}
        </div>

        <div className="pricing-content-button-container">
          <div className="pricing-content-button">
            <a href="/meeting" className="a-tag">
              Schedule a Free Trial
            </a>
          </div>
        </div>

        {/* <div className="pricing-content-cards-container">
            <div className="pricing-content-card">
                </div>
                <div className="pricing-content-card">
                </div>
        </div>
        <div className="pricing-content-cards-container">
            <div className="pricing-content-card">
                </div>
                <div className="pricing-content-card">
                </div>
        </div>
        <div className="pricing-content-cards-container">
            <div className="pricing-content-card">
                </div>
                <div className="pricing-content-card">
                </div>
        </div> */}
      </div>
      <div className="deployment-models-container">
        <div className="deployment-models-title-container">
          <div className="deployment-models-title">
            FortifAI Total Cost of Ownership Matrix
          </div>
        </div>
        <div className="deployment-models-subtitle-container">
          <div className="deployment-models-subtitle">Deployment Models</div>
        </div>
        <div className="deployment-models-cards-container">
          <div className="card">
            <div className="card-top-container">
              <img src={model1} alt="" className="card-icon" />
              <div className="card-title">On-Premise</div>
            </div>
            <div className="card-middle-container">
              <div className="card-middle-text-container">
                <img src={pricing_tick} alt="" className="card-middle-icon" />
                <div className="card-middle-text">Low implementation fee</div>
              </div>
              <div className="card-middle-text-container">
                <img src={pricing_tick} alt="" className="card-middle-icon" />
                <div className="card-middle-text">Implementation Cost</div>
              </div>
              <div className="card-middle-text-container">
                <img src={pricing_tick} alt="" className="card-middle-icon" />
                <div className="text-container">
                <div className="card-middle-text">
                  24/7 Support and upgrades 
                </div>
                <div className="card-middle-text-terms">(included in License cost)</div>
                </div>
                
              </div>
              <div className="card-middle-text-container">
                <img src={pricing_tick} alt="" className="card-middle-icon" />
                <div className="card-middle-text">
                  *Post-implementation customization request
                </div>
              </div>
            </div>
            <div className="card-bottom-container">
              <div className="pricing-content-button">
                <a href="/meeting" className="a-tag">
                Get started
                </a>
              </div>
            </div>
          </div>
         <div className="card">
            <div className="card-top-container">
              <img src={model1} alt="" className="card-icon" />
              <div className="card-title">Managed Service</div>
            </div>
            <div className="card-middle-container">
              <div className="card-middle-text-container">
                <img src={pricing_tick} alt="" className="card-middle-icon" />
                <div className="card-middle-text">License Cost</div>
              </div>
              <div className="card-middle-text-container">
                <img src={pricing_tick} alt="" className="card-middle-icon" />
                <div className="card-middle-text">Implementation Cost</div>
              </div>
              <div className="card-middle-text-container">
                <img src={pricing_tick} alt="" className="card-middle-icon" />
                <div className="text-container">
                <div className="card-middle-text">
                  24/7 Support and upgrades 
                </div>
                <div className="card-middle-text-terms">(included in License cost)</div>
                </div>
              </div>
              <div className="card-middle-text-container">
                <img src={pricing_tick} alt="" className="card-middle-icon" />
                <div className="card-middle-text">
                **Consulting Services
                </div>
              </div>
            </div>
            <div className="card-bottom-container">
              <div className="pricing-content-button">
                <a href="/meeting" className="a-tag">
                Get started
                </a>
              </div>
            </div>
          </div>
         <div className="card">
            <div className="card-top-container">
              <img src={model1} alt="" className="card-icon" />
              <div className="card-title">Project Based</div>
            </div>
            <div className="card-middle-container">
              <div className="card-middle-text-container">
                <img src={pricing_tick} alt="" className="card-middle-icon" />
                <div className="card-middle-text">License Cost</div>
              </div>
              <div className="card-middle-text-container">
                <img src={pricing_tick} alt="" className="card-middle-icon" />
                <div className="card-middle-text">Implementation Cost</div>
              </div>
              <div className="card-middle-text-container">
                <img src={pricing_tick} alt="" className="card-middle-icon" />
                <div className="card-middle-text">
                  24/7 Support and upgrades
                </div>
              </div>
              <div className="card-middle-text-container">
                <img src={pricing_tick} alt="" className="card-middle-icon" />
                <div className="card-middle-text">
                **Consulting Services
                </div>
              </div>
            </div>
            <div className="card-bottom-container">
              <div className="pricing-content-button">
                <a href="/meeting" className="a-tag">
                Get started
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="deployment-models-terms-container">
          <div className="deployment-models-terms">
            *Per Man-day basis
          </div>
          <div className="deployment-models-terms">
          **On actuals as negotiated with the service delivery partner
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
